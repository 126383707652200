import React, { useEffect, useState } from "react"
import axios from "axios"
import BlankIMG2 from "./../../../global/images/Links-BlankIMGs-02.svg"
import BlankIMG3 from "./../../../global/images/Links-BlankIMGs-03.svg"
import BlankIMG5 from "./../../../global/images/Links-BlankIMGs-05.jpeg"
import { useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom";
import style from "./Dynamics.module.scss"
import Header from "./../../UIComponents/Header/Header"
import Footer from "./../../UIComponents/Footer/Footer"
import { Container, Row, Col } from "react-bootstrap"
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont"

const Dynamics = () => {
    const { user } = useSelector((state) => state.user)
    const [results, setResults] = useState([])
    const { Bypass_Key } = useParams();
    const [resultType, setResultType] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [activeImg, setActiveImg] = useState();

    useEffect(() => {
        handleExecuteBypassDynamics()
    }, [])

    useEffect(() => {
        let activeImg
        if (resultType == 0) {
            activeImg = BlankIMG3
        } else if (resultType == 1) {
            activeImg = BlankIMG5
        }
        setActiveImg(activeImg)
    }, [resultType])

    const handleExecuteBypassDynamics = async () => {
        setIsLoading(true)
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/GENERAL/Execute_Bypass_Dynamics`,
            {
                Bypass_Key: Bypass_Key
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                }
            }
        ).then(res => {
            setResults(res.data.Data);
            setResultType(res?.data?.Data?.Result_Type)
            console.log('Data', res.data.Data)
            console.log('results', results)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            <Header />

            <InnerTitleCont
                // breadCrumbs={
                //     <>
                //         <NavLink to="/homePage">
                //             <span>
                //                 <svg
                //                     width="12"
                //                     height="12"
                //                     viewBox="0 0 12 12"
                //                     fill="none"
                //                     xmlns="http://www.w3.org/2000/svg"
                //                 >
                //                     <path
                //                         d="M10.6893 2.97833L7.32932 0.6275C6.41349 -0.0141667 5.00766 0.0208334 4.12682 0.703333L1.20432 2.98417C0.62099 3.43917 0.160156 4.3725 0.160156 5.1075V9.1325C0.160156 10.62 1.36766 11.8333 2.85516 11.8333H9.14349C10.631 11.8333 11.8385 10.6258 11.8385 9.13833V5.18333C11.8385 4.39583 11.331 3.4275 10.6893 2.97833ZM6.43682 9.5C6.43682 9.73917 6.23849 9.9375 5.99932 9.9375C5.76016 9.9375 5.56182 9.73917 5.56182 9.5V7.75C5.56182 7.51083 5.76016 7.3125 5.99932 7.3125C6.23849 7.3125 6.43682 7.51083 6.43682 7.75V9.5Z"
                //                         fill="white"
                //                     />
                //                 </svg>
                //             </span>
                //         </NavLink>

                //         <span>
                //             <svg
                //                 style={{
                //                     margin: "0 8px",
                //                     transform:
                //                         i18n.language == "ar" && "scale(-1)",
                //                 }}
                //                 width="6"
                //                 height="10"
                //                 viewBox="0 0 6 10"
                //                 fill="none"
                //                 xmlns="http://www.w3.org/2000/svg"
                //             >
                //                 <path
                //                     d="M1 1L5 5L1 9"
                //                     stroke="white"
                //                     strokeWidth="1.5"
                //                     strokeLinecap="round"
                //                     strokeLinejoin="round"
                //                 />
                //             </svg>
                //         </span>
                //         <span>{t("common:Client Porfile")}</span>
                //     </>
                // }
                // title={t("common:Client Porfile")}
                startBtn={false}
                leaveBtn={false}
            />

            <Container fluid="lg" className="mb-5">
                <Row>
                    {isLoading ?
                        <img className={style.statusImage} src={BlankIMG2} />
                        :
                        <img className={style.statusImage} src={activeImg} />
                    }
                </Row>

                {!isLoading && resultType == '0' || !isLoading && resultType == '1' ?
                    <Row>
                        <Col md={6}>
                            <div>
                                <p className={style.label}>{results ?.Title_AR}</p>
                            </div>
                            <div>
                                <p className={style.data}>{results?.Message_AR}</p>
                            </div>

                            {resultType == 1 &&
                                <>
                                    <div>
                                        <p className={style.label}>اسم المستثمر:</p>
                                        <p className={style.data}>{results?.Data.Investor_Name_AR}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>البريد الإلكتروني:</p>
                                        <p className={style.data}>{results ?.Data.Investor_Mail}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>رقم جواز السفر:</p>
                                        <p className={style.data}>{results ?.Data.Passport_Number}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>وقت مقابلة الامتثال:</p>
                                        <p className={style.data}>{results ?.Data.Interview_Time}</p>
                                    </div>
                                </>
                            }
                        </Col>

                        <Col md={6} className={style.englishData}>
                            <div>
                                <p className={style.label}>{results?.Title_EN}</p>
                            </div>
                            <div>
                                <p className={style.data}>{results?.Message_EN}</p>
                            </div>

                            {resultType == 1 &&
                                <>
                                    <div>
                                        <p className={style.label}>Investor Name:</p>
                                        <p className={style.data}>{results?.Data.Investor_Name_EN}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>Mail:</p>
                                        <p className={style.data}>{results?.Data.Investor_Mail}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>Passport Number:</p>
                                        <p className={style.data}>{results?.Data.Passport_Number}</p>
                                    </div>
                                    <div>
                                        <p className={style.label}>Compliance Interview Time:</p>
                                        <p className={style.data}>{results?.Data.Interview_Time}</p>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                    : null
                }
            </Container>

            <Footer />
        </>
    )
}

export default Dynamics