import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { 
  registerIndividualClient, 
  registerContratorClient, 
  registerConsultativeClient, 
  registerGovClient, 
  checkLoginName, 
  checkPhoneNo,
  checkEmailAddress,
  checkPasswordPower, 
  registerotherClients,
  sendMailVerification,
  checkMailVerification
} from "api/registerAPI";
import { getDealingTypes, getEntites, getEmirates } from "api/dropdownsAPI";

export const fetchEmirates = createAsyncThunk("register/fetchEmirates", async (param, { signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getEmirates(param, {
      cancelToken: source.token,
    });
    if (response?.Status) {
      return response.Data;
    }
  } catch (err) {
    if (!err?.Status) {
      return rejectWithValue(err.Data);
    }
    if (axios.isCancel(err)) {
      rejectWithValue(err.message);
    }
  }
});

const slice = createSlice({
  name: "register",
  initialState: {
    dealingTypes: [],
    governmentEntities: [],
    emirates: [],
    loadingEmirates: "idle",
    validLoginName: "",
    LoginNameErrAR: "",
    LoginNameErrEN: "",
    validPhoneNumber: "",
    PhoneNumberErrAR: "",
    PhoneNumberErrEN: "",
    validEmail: "",
    EmailErrAR: "",
    EmailErrEN: "",
    validPassword: "",
    passwordPower: -1,
  },
  reducers: {
    registerSuccess: (state) => {
      state.validLoginName = "";
      state.LoginNameErrAR = "";
      state.LoginNameErrEN = "";
      state.validPhoneNumber = "";
      state.PhoneNumberErrAR = "";
      state.PhoneNumberErrEN = "";
      state.validEmail = "";
      state.EmailErrAR = "";
      state.EmailErrEN = "";
      state.passwordPower = -1;
      state.validPassword = "";
    },
    dealingTypesSuccess: (state, { payload }) => {
      state.dealingTypes = payload;
    },
    governmentEntitiesSuccess: (state, { payload }) => {
      state.governmentEntities = payload;
    },
    checkLoginSuccess: (state, { payload }) => {
      state.validLoginName = payload?.Login_Name_Valid;
      state.LoginNameErrEN = payload?.Login_Name_Error_EN;
      state.LoginNameErrAR = payload?.Login_Name_Error_AR;
    },
    checkPhoneNoSuccess: (state, { payload }) => {
      state.validPhoneNumber = payload?.Client_Phone_Valid;
      state.PhoneNumberErrAR = payload?.Error_AR;
      state.PhoneNumberErrEN = payload?.Error_EN;
    },
    checkEmailSuccess: (state, { payload }) => {
      state.validEmail = payload?.Client_Mail_Valid;
      state.EmailErrAR = payload?.Error_AR;
      state.EmailErrEN = payload?.Error_EN;
    },
    checkPasswordSuccess: (state, { payload }) => {
      state.validPassword = payload?.Password_Valid;
      state.passwordPower = payload?.Password_Power;
    },
    OTPVerificationSuccess: (state, { payload }) => {
      state.OTPExpireTime = payload?.OTP_Expire_Time;
      state.UserOTPId = payload?.User_OTP_Id;
    },
    EmailVerificationSuccess: (state, { payload }) => {
      state.EmailIsVerified = payload
    }
  },
  extraReducers: {
    [fetchEmirates.pending]: (state) => {
      if (state.loadingEmirates === "idle") {
        state.loadingEmirates = "pending";
      }
    },
    [fetchEmirates.fulfilled]: (state, { payload }) => {
      if (state.loadingEmirates === "pending") {
        state.loadingEmirates = "idle";
        state.emirates = payload;
      }
    },
    [fetchEmirates.rejected]: (state, action) => {
      if (state.loadingEmirates === "pending") {
        state.loadingEmirates = "idle";
        state.error = action.error;
      }
    },
  },
});
export default slice.reducer;

export const { 
  registerSuccess, 
  dealingTypesSuccess, 
  governmentEntitiesSuccess, 
  emiratesSuccess, 
  checkLoginSuccess, 
  checkPhoneNoSuccess, 
  checkEmailSuccess,
  checkPasswordSuccess,
  OTPVerificationSuccess,
  EmailVerificationSuccess
} = slice.actions;

export const registerIndividual = (param, source, setSubmitting, setShowSubmitLoading, resetForm) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await registerIndividualClient(param, cancelToken);

    if (res?.Status) {
      dispatch(registerSuccess());
      resetForm()
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
    setShowSubmitLoading(false);
  }
};
export const registerContrator = (param, source, setSubmitting, setShowSubmitLoading, resetForm) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await registerContratorClient(param, cancelToken);

    if (res?.Status) {
      dispatch(registerSuccess());
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
    resetForm()
    setShowSubmitLoading(false);
  }
};
export const registerConsultative = (param, source, setSubmitting, setShowSubmitLoading, resetForm) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await registerConsultativeClient(param, cancelToken);

    if (res?.Status) {
      dispatch(registerSuccess());
      resetForm()
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
    setShowSubmitLoading(false);
  }
};
export const registerGovermentalEntities = (param, source, setSubmitting, setShowSubmitLoading, resetForm) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await registerGovClient(param, cancelToken);

    if (res?.Status) {
      dispatch(registerSuccess());
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
    resetForm()
    setShowSubmitLoading(false);
  }
};
export const registerOthersProfile = (param, source, setSubmitting, setShowSubmitLoading, resetForm) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await registerotherClients(param, cancelToken);

    if (res?.Status) {
      dispatch(registerSuccess());
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
    resetForm()
    setShowSubmitLoading(false);
  }
};
export const checkLogin = (param, source) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await checkLoginName(param, cancelToken);

    if (res?.Status) {
      dispatch(checkLoginSuccess(res.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    //cleansing callbacks
  }
};
export const checkPhone = (param, source) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await checkPhoneNo(param, cancelToken);

    if (res?.Status) {
      dispatch(checkPhoneNoSuccess(res?.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
  }
};
export const checkEmail = (param, source) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await checkEmailAddress(param, cancelToken);

    if (res?.Status) {
      dispatch(checkEmailSuccess(res?.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
  }
};
export const checkPassword = (param, source) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await checkPasswordPower(param, cancelToken);

    if (res?.Status) {
      dispatch(checkPasswordSuccess(res.Data));
    }
    if (!res?.Status) {
      // api errors
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    //cleansing callbacks
  }
};
export const fetchEntities = (param, source) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }

    const res = await getEntites(param, cancelToken);

    if (res?.Status) {
      dispatch(governmentEntitiesSuccess(res.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    // setSubmitting(false);
  }
};
export const sendMailOTPVerification = (param, source, setShowSubmitLoading) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }

    const res = await sendMailVerification(param, cancelToken);

    if (res?.Status) {
      dispatch(OTPVerificationSuccess(res.Data));
    }
    if (!res?.Status) {}
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setShowSubmitLoading(false);
  }
};
export const checkMailOTPVerification = (param, source, setShowSubmitLoading) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }

    const res = await checkMailVerification(param, cancelToken);

    if (res?.Status) {
      dispatch(EmailVerificationSuccess(res));
    }
    if (!res?.Status) {}
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      // console.error(error);
    }
  } finally {
    setShowSubmitLoading(false);
  }
};

// export const fetchDealingTypes = (param, source) => async (dispatch) => {
//   try {
//     let cancelToken;
//     if (source) {
//       cancelToken = { cancelToken: source.token };
//     }

//     const res = await getDealingTypes(param, cancelToken);

//     if (res?.Status) {
//       dispatch(dealingTypesSuccess(res.Data));
//     }
//     if (!res?.Status) {
//     }
//   } catch (error) {
//     if (axios.isCancel(error)) {
//       console.log("cancelled");
//     } else {
//       console.error(error);
//     }
//   } finally {
//     // setSubmitting(false);
//   }
// };

// export const fetchEmirates = (param, source) => async (dispatch) => {
//   try {
//     let cancelToken;
//     if (source) {
//       cancelToken = { cancelToken: source.token };
//     }

//     const res = await getEmirates(param, cancelToken);

//     if (res?.Status) {
//       dispatch(emiratesSuccess(res.Data));
//     }
//     if (!res?.Status) {
//     }
//   } catch (error) {
//     if (axios.isCancel(error)) {
//       console.log("cancelled");
//     } else {
//         console.error(error);
//     }
//   } finally {
//     // setSubmitting(false);
//   }
// };
