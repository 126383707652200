import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import style from "./DyanmicField.module.scss";
import { Col, Form } from "react-bootstrap";
import debounce from "lodash.debounce";
import { getServicesLookupsValues } from "api/dropdownsAPI";
import { SelectBox } from "devextreme-react/select-box";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styleNew from "./NewDyanmicField.module.scss";
import arrowDownDropDown from "./iconsFolder/arrowDownDropDown.svg";
import { useSelector } from "react-redux";
import DataSource from "devextreme/data/data_source";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
		{
			padding: 0,
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
	},
}));

const Dropdown = ({
	item,
	defaultValue,
	onSave = async () => { },
	reloadDropdown,
	validatedMsg,
	isFocus
}) => {
	const [value, setValue] = useState("");
	const [options, setOptions] = useState([]);
	const { t, i18n } = useTranslation("common");
	const { clientProfiles } = useSelector((state) => state.user);
	const { requestId } = useSelector((state) => state.service.serviceRequest);
	const [isTextExpanded, setIsTextExpanded] = useState(false);
	const dropdownRef = useRef();
	const { requestId: paramRequestId } = useParams();
	const [showErrorMsg, setShowErrorMsg] = useState(true);

	const handleExpandLabel = () => {
		setIsTextExpanded((prev) => !prev);
	};

	useEffect(() => {
		if (defaultValue && typeof defaultValue() === "string") {
			setValue(parseInt(defaultValue()));
		}
		getDropdownValue();
	}, []);

	useEffect(() => {
		reloadDropdown?.map((drop) => {
			if (
				drop.Department_Service_Detail_Id ==
				item.Department_Service_Detail_Id
			) {
				getDropdownValue();
			}
		});
	}, [reloadDropdown]);

	useEffect(() => {
		if (isFocus == item.Service_Detail_Id) {
			dropdownRef && dropdownRef?.current?.instance?.focus();
			console.log(dropdownRef)
		}
	}, [isFocus])

	const getDropdownValue = () => {
		getServicesLookupsValues({
			client_Profile_Id: clientProfiles?.Client_Profile_Id,
			Request_Draft_Id: requestId ? requestId : paramRequestId,
			// Request_Draft_Id: requestId,
			Department_Service_Detail_Id: item.Department_Service_Detail_Id,
		})
			.then((response) => {
				response?.Status && setOptions(response?.Data);
			})
			.catch((e) => console.error(e));
	};

	const handleChangeWithDebounce = debounce(async (e) => {
		if (e.value == parseInt(defaultValue())) {
			return;
		} else if (e) {
			let obj = { target: {} };
			obj.target.value = e.value;
			await onSave(obj, item);
			// setValue(value);
		}
	}, 500);

	const selectBoxData = new DataSource({
		store: {
			type: "array",
			data: options,
			key: "Record_Id",
		},
		paginate: true,
		pageSize: 30,
	});

	return (
		<Col
			// className={style.dynamics}
			key={item.Department_Service_Detail_Id}
			md={12}
			id={item.Department_Service_Detail_Title_EN}
		>
			{item.Department_Service_Detail_Required ? (
				<Form.Label className={styleNew.textLabel}>
					{i18n.language === "ar"
						? item?.Department_Service_Detail_Title_AR
						: item?.Department_Service_Detail_Title_EN}{" "}
					<span className={style.required}>*</span>
				</Form.Label>
			) : (
				<Form.Label
					className={
						isTextExpanded
							? styleNew.textLabel
							: styleNew.textExpandedLabel
					}
					onClick={() =>
						handleExpandLabel(
							setIsTextExpanded,
							i18n.language === "ar"
								? item?.Department_Service_Detail_Title_AR
								: item?.Department_Service_Detail_Title_EN
						)
					}
				>
					{i18n.language === "ar"
						? item?.Department_Service_Detail_Title_AR
						: item?.Department_Service_Detail_Title_EN}
				</Form.Label>
			)}

			<div className={style.textValidationContainer}>
				<Form.Control
					type="text"
					className={style.hiddenInput}
					value={value}
					required={
						item.Department_Service_Detail_Required ? true : false
					}
				/>

				<SelectBox
					ref={dropdownRef}
					className={styleNew.dynamicDropDownBox}
					dataSource={selectBoxData}
					// dataSource={options}
					placeholder={t("select")}
					name={(item.Service_Detail_Id).toString()}
					searchEnabled={true}
					showDataBeforeSearch={options.length > 30 ? false : true}
					minSearchLength={1}
					noDataText={t("There is no data")}
					value={value}
					displayExpr={
						i18n.language === "en"
							? "Record_Name_EN"
							: "Record_Name_AR"
					}
					rtlEnabled={i18n.language === "ar" ? true : false}
					valueExpr={"Record_Id"}
					onValueChanged={(e) => {
						if (!isNaN(e.value)) {
							setValue(e.value);
							handleChangeWithDebounce(e);
							setShowErrorMsg(false)
						}
					}}
					dropDownButtonRender={() => (
						<img
							style={{ width: "12px", height: "12px" }}
							alt="dropdown-icon"
							src={arrowDownDropDown}
						/>
					)}
				/>

				{/* <Autocomplete
        className={style.dynamicDropDown}
        name={item.Service_Detail_Id}
        options={options && options}
        getOptionLabel={(option) =>
          (i18n.language === "en"
            ? option.Record_Name_EN
            : option.Record_Name_AR) || ""
        }
        getOptionSelected={(option, value) =>
          option?.Record_Id === value?.Record_Id
        }
        value={value}
        onChange={(e, value) => {
          handleChangeWithDebounce(e, value);
        }}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value);
        }}
        classes={classes}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            name={item.Service_Detail_Id}
            fullWidth
            variant="outlined"
            required={item.Department_Service_Detail_Required ? true : false}
          />
        )}
      /> */}

				{/* <SelectBox
        dataSource={options}
        displayExpr="Record_Name_AR"
        searchEnabled={true}
        placeholder="--اختر--"
        name={item.Service_Detail_Id}
        defaultValue={parseInt(value)}
        className="w-100"
        valueExpr="Record_Id"
        onValueChanged={handleChangeWithDebounce}
        required={true}
      /> */}
				{/* <input list="brow"></input> */}
				{/* <Form.Control
        as="select"
        name={item.Service_Detail_Id}
        onChange={handleChangeWithDebounce}
        required={item.Department_Service_Detail_Required ? true : false}
      >
        <option value="" hidden disabled selected>
          --أختر--
        </option>
        {options?.length > 0 &&
          options.map((data) => (
            <option
              key={data?.Record_Id}
              value={data.Record_Id.toString()}
              selected={data.Record_Id.toString() === value}
            >
              {data?.Record_Name_AR}
            </option>
          ))}
      </Form.Control> */}
				{/* <Form.Text className={style.text}>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوىة</Form.Text> */}
			</div>

			{showErrorMsg && validatedMsg?.map(vm => {
				if (vm?.Parameter_Id == item?.Service_Detail_Id) {
					return (
						<div className={styleNew.errMsg}>
							{i18n.language == 'ar' ? vm?.Errors_AR?.map(m => <div>{m}</div>) : vm?.Errors_EN?.map(m => <div>{m}</div>)}
						</div>
					)
				}
			})}
		</Col>
	);
};

export default Dropdown;
