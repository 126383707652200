import { post } from "./base";
import qs from "qs";
import { loadState } from "../utils";

export async function getDealingTypes(param, cancelToken) {
  const url = `${"SYSTEM/DDL/GET_DEALING_TYPES"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function getEntites(param, cancelToken) {
  const url = `${"SYSTEM/DDL/GET_GOVERNMENT_ENTITIES"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function getEmirates(param, cancelToken) {
  const url = `${"SYSTEM/DDL/GET_EMIRATES"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}

export async function getServicesLookupsValues(param) {
  // const url = `${"SYSTEM/DDL/GET_SERVICES_LOOKUPS_VALUES"}`;
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_DRAFT_DROPDWON_DATA_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const res = await post(url, qs.stringify(param), options);
  return res;
}

export async function getDraftExternalDataList(param) {
  // const url = `${"PORTAL/CLIENTS/REQUESTS/GET_DRAFT_EXTERNAL_DATA_LIST"}`;
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_DRAFT_DROPDWON_DATA_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };

  const res = await post(url, qs.stringify(param), options);
  return res;
}

export async function getRequestExternalDataList(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_EXTERNAL_DATA_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };

  const res = await post(url, qs.stringify(param), options);
  return res;
}

export async function getRequestDataList(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_DROPDWON_DATA_LIST"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };

  const res = await post(url, qs.stringify(param), options);
  return res;
}

export async function GetDraftInputsDataValidation(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_DRAFT_INPUTS_DATA_VALIDATION"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };

  const res = await post(url, qs.stringify(param), options);
  return res;
}

export async function GetRequestInputsDataValidation(param) {
  const url = `${"PORTAL/CLIENTS/REQUESTS/GET_REQUEST_INPUTS_DATA_VALIDATION"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` } };

  const res = await post(url, qs.stringify(param), options);
  return res;
}

